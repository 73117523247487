import React, { useEffect, useState } from "react";
import { Routes, Route, useMatch, useLocation } from "react-router-dom";
import './index.css';
import { Toaster } from 'react-hot-toast';

// Pages
import Home from './pages/home';
import WebsiteOnePage from "./pages/Serviços/websiteOnePage";
import WebsiteMultiPage from "./pages/Serviços/websiteMultiPage";
import WebApp from "./pages/Serviços/webapp";
import PrivacyPolicy from "./pages/privacyPolicy";
import _404Page from "./pages/_404Page";
import ThankYou from "./pages/thankYou";

// Components
import MouseEffect from './components/mouseEffect';

function App() {
  const [isMouseEffectVisible, setIsMouseEffectVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsMouseEffectVisible(true);
  };

  const handleMouseLeave = () => {
    setIsMouseEffectVisible(false);
  };

  return (
    <div className='relative overflow-x-hidden bg-stone-900 cursor-none'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Toaster />
      {isMouseEffectVisible && <MouseEffect className="mouseEffect" />}
      <div className='max-w-[1920px] mx-auto'>
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/websiteOnePage' exact element={<WebsiteOnePage />} />
          <Route path='/websiteMultiPage' exact element={<WebsiteMultiPage />} />
          <Route path='/webApp' exact element={<WebApp />} />
          <Route path='/privacyPolicy' exact element={<PrivacyPolicy />} />
          <Route path='/thankYou' exact element={<ThankYou />} />
          <Route path="*" element={<_404Page />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
