import React from 'react';
import NavbarSecundary from '../components/navbarSecundary';

const ThankYou = () => {

    return (
        <main className='flex flex-col min-h-screen px-8 lg:px-24'>
            <NavbarSecundary />
            <div className='flex flex-col justify-center flex-grow'>
                <h1 className="title">Mensagem Enviada!</h1>
                <h3 className='mt-4 text-2xl font-bold tracking-wide text-center text-midColor md:text-3xl'>Em breve entraremos em contacto consigo.</h3>
                <img className='w-64 py-12 mx-auto select-none sm:w-72' src={`${process.env.PUBLIC_URL}/images/message.svg`} title='404 Ilustration' alt='404 Ilustration' />
                <a href={`${process.env.PUBLIC_URL}/`} className="w-64 mx-auto button">Voltar ao Início</a>
            </div>
        </main>
    );
};

export default ThankYou;