import React from 'react';
import { Link } from 'react-scroll'

const Navbar = () => {

    return (
        <nav className='max-w-[1920px] w-full mx-auto z-10 relative'>
            <div className='flex justify-center py-10 md:justify-between'>
                <div>
                    <img className='w-24 select-none' src={`${process.env.PUBLIC_URL}/images/logoNOBG.png`} title='jtlstudios Logo' alt='jtlstudios logo' />
                </div>
                <ul className='items-center hidden md:flex gap-x-10'>
                    <li className='navbarLink'>
                        <Link to="sobre" spy={true} smooth={true} offset={-20} duration={500}>
                            Sobre
                        </Link>
                    </li>
                    <li className='navbarLink'>
                        <Link to="portfolio" spy={true} smooth={true} offset={-20} duration={500}>
                            Portfolio
                        </Link>
                    </li>
                    <li className='navbarLink'>
                        <Link to="services" spy={true} smooth={true} offset={-20} duration={500}>
                            Serviços
                        </Link>
                    </li>
                    <li className='navbarLink'>
                        <Link to="form" spy={true} smooth={true} offset={-20} duration={500}>
                            Entre em contacto
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;