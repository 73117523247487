import React, { useEffect, useState } from 'react';
import NavbarSecundary from '../../components/navbarSecundary';
import Form from '../../components/form';
import ButtonBack from '../../components/buttonBack';
import { FaMinus, FaPlus } from 'react-icons/fa6';
import { Link } from 'react-scroll'
import Footer from '../../components/footer';

const WebsiteMultiPage = () => {

    const [pos, setPos] = useState(-800);

    useEffect(() => {
        const handleScroll = () => {
            setPos((window.scrollY * 0.5) - 800);
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    return (
        <>
            <NavbarSecundary />
            <ButtonBack />
            <main className='flex flex-col items-center px-8 lg:px-24'>
                <div className='lg:grid grid-cols-7 gap-x-32 items-center min-h-[90vh]'>
                    <div className='flex flex-col items-center col-span-4'>
                        <h1 className="title">Website<br />(Multi página)</h1>
                        <hr className='h-[1px] my-5 w-full border-none bg-gradient-to-r orangeToRedGradient' />
                        <h2 className='mb-6 text-lg font-semibold tracking-wider text-center text-white sm:text-justify sm:text-xl md:text-2xl'>
                            Um website com várias páginas, distribuindo informações de forma segmentada e organizada.
                        </h2>
                        <Link className="w-64 mx-auto button" to="form" spy={true} smooth={true} offset={-20} duration={500}>
                            Saber mais
                        </Link>
                    </div>
                    <div className='my-6 lg:my-0 lg:col-span-3'>
                        <a href="https://escola-conducao.jtlstudios.pt">
                            <img className='mx-auto w-96 lg:w-full' src={`${process.env.PUBLIC_URL}/images/projetos/escolaDeConducaoMac.png`} alt='Exemplo de website single page' />
                        </a>
                    </div>
                </div>
                <div>
                    <div className='absolute z-50 title text-white select-none whitespace-nowrap font-["ethnocentric"]' style={{ left: `${pos}px` }}>
                        JTL Studios JTL Studios JTL Studios JTL Studios  JTL Studios JTL Studios JTL Studios JTL Studios  JTL Studios JTL Studios JTL Studios JTL Studios JTL Studios JTL Studios JTL Studios JTL Studios
                    </div>
                </div>
                <div className='my-24 sm:my-48'>
                    <div className='px-8 py-6'>
                        <h1 className="title">Website (Multi página)</h1>
                        <p className='mt-6 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                            Com a ajuda da <span className='font-bold text-brandOrange'>JTL STUDIOS </span>
                            poderá lançar a sua marca online com um website de multiplas páginas totalmente
                            <span className='font-bold text-brandOrange'> personalizáveis</span> e de acordo com as suas necessidades.
                            Um website com multiplas única permite
                            <span className='font-bold text-brandOrange'> organizar o conteúdo e atrair mais clientes </span>
                            para o seu negócio.
                            A  sua presença no <span className='font-bold text-brandOrange'>mundo digital</span> irá aumentar o alcance da sua marca exponêncialmente.
                        </p>
                        <div className='my-12'>
                            <h2 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>O que é um website (multi página) ?</h2>
                            <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                É um website onde o seu conteúdo está <span className='font-bold text-brandOrange'>distribuido e organizado através de multiplas páginas. </span>
                                Permitindo ter várias páginas para que os seus clientes a explorem e fiquem a conhecer a identidade do seu negócio, de modo a que estes contruam confiança nos seus produtos/serviços.
                            </p>
                        </div>
                        <div className='mt-12'>
                            <h2 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>Será que um website de multiplas páginas é o ideal para mim ?</h2>
                            <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                Se pretender tirar o máximo de proveito do mundo digital e ter um website estruturado onde a informação não esteja disposta em uma única página
                                então um website de multíplas páginas é o ídeal para o seu negócio. Este será uma importante ferramenta para
                                <span className='font-bold text-brandOrange'> expandir o seu negócio.</span>
                            </p>
                        </div>
                    </div>

                    <div className='px-8 mt-24 sm:mt-48'>
                        <h2 className='title'> Perguntas Frequentes</h2>
                        <div className='mt-12'>
                            <div className='my-12'>
                                <h3 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>Como funciona um formulário de contacto ?</h3>
                                <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                    Se desejar adicionar um formulário de contacto ao seu website, este irá será similar ao formulário de contacto presente no nosso website.
                                </p>
                                <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                    Após ser preenchido <span className='font-bold text-brandOrange'>será enviado um email </span>
                                    para uma conta de email à sua escolha
                                    <span className='font-bold text-brandOrange'> com as informações preenchidas </span> no formuário.
                                    Caso pretanda guardar informações em uma base de dados haverá um custo adicional, para mais informações contacte-nos.
                                </p>
                            </div>
                            <div className='my-12'>
                                <h3 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>O que siginifica registo de domínio ?</h3>
                                <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                    Um domínio é o <span className='font-bold text-brandOrange'>endereço do seu website na internet</span>, quando pesquisa: 'www.jtlstudios.pt' o 'jtlstudios.pt' é o domínio do nosso website.
                                </p>
                                <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                    Pode optar por um dominio predefinido por exemplo: 'www.o-nome-do-site.netlify.app'
                                    apesar de não haver custos associados
                                    é uma abordagem menos profissional e irá afetar negativamente o seu posicionamento nas pesquisas do google.
                                </p>
                                <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                    Caso pretenda que seja efetuado o registo de domínio, 'www.nome-do-site.pt'
                                    iremos registar o seu domínio o que irá ter um custo associado o qual irá pagar anualmente à entidade provedora domínio.
                                    Um domínio registado demostra profissionalismo e credibilidade junto dos seus clientes.
                                </p>
                                <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                    Na JTL trabalhamos com ambos os tipos de domínio e
                                    poderá escolher <span className='font-bold text-brandOrange'>qualquer um dos dois</span> sem ter nenhum custo associado para comnosco.
                                </p>
                                <div className='grid grid-cols-1 mt-6 sm:grid-cols-2 gap-y-5'>
                                    <div className='w-[60%] mx-auto '>
                                        <h4 className='text-xl font-bold tracking-wide text-white'>
                                            Domínio predefinido
                                            <span className='text-sm text-gray-100'> (nome-do-site.netlify.app)</span>
                                        </h4>
                                        <div className='flex items-center mt-3 text-green-600 gap-x-3'>
                                            <FaPlus />
                                            <p>Gratuito</p>
                                        </div>
                                        <div className='flex items-center text-red-600 gap-x-3'>
                                            <FaMinus />
                                            <p>Limitações na costumização</p>
                                        </div>
                                        <div className='flex items-center text-red-600 gap-x-3'>
                                            <FaMinus />
                                            <p>Imagem menos profissional</p>
                                        </div>
                                        <div className='flex items-center text-red-600 gap-x-3'>
                                            <FaMinus />
                                            <p>Afeta o desempenho do site nas pesquisas no google</p>
                                        </div>
                                    </div>
                                    <div className='w-[60%] mx-auto '>
                                        <h4 className='text-xl font-bold tracking-wide text-white'>
                                            Domínio registado
                                            <span className='text-sm text-gray-100'> (nome-do-site.pt)</span>
                                        </h4>
                                        <div className='flex items-center mt-3 text-green-600 gap-x-3'>
                                            <FaPlus />
                                            <p>Totalmente personalizável</p>
                                        </div>
                                        <div className='flex items-center text-green-600 gap-x-3'>
                                            <FaPlus />
                                            <p>Demostra Credibilidade e Profissionalismo</p>
                                        </div>
                                        <div className='flex items-center text-green-600 gap-x-3'>
                                            <FaPlus />
                                            <p>Melhora o desempenho do site nas pesquisas no google</p>
                                        </div>
                                        <div className='flex items-center text-red-600 gap-x-3'>
                                            <FaMinus />
                                            <p>Custo anual</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h3 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>Como funciona o processo de aquisição de um webiste ?</h3>
                            <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                A <span className='font-bold text-brandOrange'>JTL STUDIOS pretende a total satisfação dos seus clientes</span>,
                                deste modo antes de começarmos o desenvolvimento avaliaremos as suas
                                necessiades e garantimos que o nosso serviço vá de encontro as mesmas,
                                preservando a identidade da sua marca.
                                Ao longo do do processo de desenvolvimento teremos tantas reuniões de avaliação de progresso quanto desejar
                                garantindo que o website esteja <span className='font-bold text-brandOrange'>100% à sua medida.</span>
                            </p>
                            <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                Depois do desenvolvimento é feita uma reunião final para garantir que todos os objetivos foram cumpridos.
                                O website será colocado num domínio à sua escolha e entregue, o pagamento só é efetuado após a entrega caso
                                esteja <span className='font-bold text-brandOrange'>100% satisfeito</span>, caso contrário será devolvido o site sem qualquer custo associado.
                            </p>
                        </div>
                    </div>
                </div>
                <Form defaultValue={"webMULTI"} />
            </main >
            <Footer />
        </>
    );
};

export default WebsiteMultiPage;