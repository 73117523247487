import React, { useEffect, useState } from 'react';
import NavbarSecundary from '../../components/navbarSecundary';
import Form from '../../components/form';
import ButtonBack from '../../components/buttonBack';
import { FaMinus, FaPlus } from 'react-icons/fa6';
import { Link } from 'react-scroll'
import Footer from '../../components/footer';

const WebsiteOnePage = () => {

    const [pos, setPos] = useState(-800);

    useEffect(() => {
        const handleScroll = () => {
            setPos((window.scrollY * 0.5) - 800);
            console.log()
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    return (
        <>
            <NavbarSecundary />
            <ButtonBack />
            <main className='flex flex-col items-center px-8 lg:px-24'>
                <div className='lg:grid grid-cols-7 gap-x-32 items-center min-h-[90vh]'>
                    <div className='flex flex-col items-center col-span-4'>
                        <h1 className="title">Website<br />(Página Única)</h1>
                        <hr className='h-[1px] my-5 w-full border-none bg-gradient-to-r orangeToRedGradient' />
                        <h2 className='mb-6 text-lg font-semibold tracking-wider text-center text-white sm:text-justify sm:text-xl md:text-2xl'>
                            Website minimalista, condensando todas as informações em uma única página.
                        </h2>
                        <Link className="w-64 mx-auto button" to="form" spy={true} smooth={true} offset={-20} duration={500}>
                            Saber mais
                        </Link>
                    </div>
                    <div className='my-6 lg:my-0 lg:col-span-3'>
                        <a href="https://optica.jtlstudios.pt">
                            <img className='mx-auto w-96 lg:w-full' src={`${process.env.PUBLIC_URL}/images/projetos/opticaMac.png`} alt='Exemplo de website single page' />
                        </a>
                    </div>
                </div>
                <div>
                    <div className='absolute z-50 title text-white select-none whitespace-nowrap font-["ethnocentric"]' style={{ left: `${pos}px` }}>
                        JTL Studios JTL Studios JTL Studios JTL Studios  JTL Studios JTL Studios JTL Studios JTL Studios  JTL Studios JTL Studios JTL Studios JTL Studios JTL Studios JTL Studios JTL Studios JTL Studios
                    </div>
                </div>
                <div className='my-24 sm:my-48'>
                    <div className='px-8 py-6'>
                        <h2 className="title">Website (Página Única)</h2>
                        <p className='mt-6 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                            Com a ajuda da <span className='font-bold text-brandOrange'>JTL STUDIOS </span>
                            poderá lançar a sua marca online com um website de página única totalmente
                            <span className='font-bold text-brandOrange'> personalizável</span> e de acordo com as suas necessidades. Um website de página única garante a sua
                            presença no <span className='font-bold text-brandOrange'>mundo digital</span> desta forma poderá aumentar o alcance da sua marca exponêncialmente.
                        </p>
                        <div className='my-12'>
                            <h2 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>O que é um website de página única ?</h2>
                            <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                Um website de página única é um website que <span className='font-bold text-brandOrange'> condensa toda a informação em uma única página</span>, logo não têm navegação interna.
                                Apesar da sua dimensão não deixa de ser uma ferramenta extraordinária para mostrar aos seus clientes
                                a identidade da sua marca e fazê-los comprar os seus produtos/serviços.
                            </p>
                        </div>
                        <div className='mt-12'>
                            <h2 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>Será que um website de página uníca é o ideal para mim ?</h2>
                            <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                Depende de muitos fatores, mas se ainda não têm uma presença digital e pretende
                                dar os seus <span className='font-bold text-brandOrange'>primeros passos no mundo virtual</span> a JTL Studios recomenda o website de página única
                                pois não é necessário um grande investimento para criar uma <span className='font-bold text-brandOrange'>presença digital inpactante.</span>
                            </p>
                        </div>
                    </div>

                    <div className='px-8 mt-24 sm:mt-48'>
                        <h2 className='title'> Perguntas Frequentes</h2>
                        <div className='my-12'>
                            <h3 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>No futuro posso transformar o meu website de página única em um website multipágina ?</h3>
                            <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                Claro um website de página única é <span className='font-bold text-brandOrange'>100% escalável</span> e pode ser transformado num website multipágina se assim o desejar.
                                Por já ter a base não precisará de pagar o preço total de um website multipágina novo
                                bastando pagar o preço até prefazer a diferença.
                            </p>
                        </div>
                        <div className='my-12'>
                            <h3 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>O que siginifica registo de domínio ?</h3>
                            <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                Um domínio é o <span className='font-bold text-brandOrange'>endereço do seu website na internet</span>, quando pesquisa: 'www.jtlstudios.pt' o 'jtlstudios.pt' é o domínio do nosso website.
                            </p>
                            <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                Pode optar por um dominio predefinido por exemplo: 'www.o-nome-do-site.netlify.app'
                                apesar de não haver custos associados
                                é uma abordagem menos profissional e irá afetar negativamente o seu posicionamento nas pesquisas do google.
                            </p>
                            <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                Caso pretenda que seja efetuado o registo de domínio, 'www.nome-do-site.pt'
                                iremos registar o seu domínio o que irá ter um custo associado o qual irá pagar anualmente à entidade provedora domínio.
                                Um domínio registado demostra profissionalismo e credibilidade junto dos seus clientes.
                            </p>
                            <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                Na JTL trabalhamos com ambos os tipos de domínio e
                                poderá escolher <span className='font-bold text-brandOrange'>qualquer um dos dois</span> sem ter nenhum custo associado para comnosco.
                            </p>
                            <div className='grid grid-cols-1 mt-6 sm:grid-cols-2 gap-y-5'>
                                <div className='w-[60%] mx-auto '>
                                    <h4 className='text-xl font-bold tracking-wide text-white'>
                                        Domínio predefinido
                                        <span className='text-sm text-gray-100'> (nome-do-site.netlify.app)</span>
                                    </h4>
                                    <div className='flex items-center mt-3 text-green-600 gap-x-3'>
                                        <FaPlus />
                                        <p>Gratuito</p>
                                    </div>
                                    <div className='flex items-center text-red-600 gap-x-3'>
                                        <FaMinus />
                                        <p>Limitações na costumização</p>
                                    </div>
                                    <div className='flex items-center text-red-600 gap-x-3'>
                                        <FaMinus />
                                        <p>Imagem menos profissional</p>
                                    </div>
                                    <div className='flex items-center text-red-600 gap-x-3'>
                                        <FaMinus />
                                        <p>Afeta o desempenho do site nas pesquisas no google</p>
                                    </div>
                                </div>

                                <div className='w-[60%] mx-auto '>
                                    <h4 className='text-xl font-bold tracking-wide text-white'>
                                        Domínio registado
                                        <span className='text-sm text-gray-100'> (nome-do-site.pt)</span>
                                    </h4>
                                    <div className='flex items-center mt-3 text-green-600 gap-x-3'>
                                        <FaPlus />
                                        <p>Totalmente personalizável</p>
                                    </div>
                                    <div className='flex items-center text-green-600 gap-x-3'>
                                        <FaPlus />
                                        <p>Demostra Credibilidade e Profissionalismo</p>
                                    </div>
                                    <div className='flex items-center text-green-600 gap-x-3'>
                                        <FaPlus />
                                        <p>Melhora o desempenho do site nas pesquisas no google</p>
                                    </div>
                                    <div className='flex items-center text-red-600 gap-x-3'>
                                        <FaMinus />
                                        <p>Custo anual</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-12'>
                            <h3 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>Como funciona o processo de aquisição de um webiste ?</h3>
                            <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                A <span className='font-bold text-brandOrange'>JTL STUDIOS pretende a total satisfação dos seus clientes</span>,
                                deste modo antes de começarmos o desenvolvimento avaliaremos as suas
                                necessiades e garantimos que o nosso serviço vá de encontro as mesmas,
                                preservando a identidade da sua marca.
                                Ao longo do do processo de desenvolvimento teremos tantas reuniões de avaliação de progresso quanto desejar
                                garantindo que o website esteja <span className='font-bold text-brandOrange'>100% à sua medida.</span>
                            </p>
                            <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                Depois do desenvolvimento é feita uma reunião final para garantir que todos os objetivos foram cumpridos.
                                website será colocado num domínio à sua escolha e entregue o pagamento só é efetuado após a entrega caso
                                esteja <span className='font-bold text-brandOrange'>100% satisfeito</span>, caso contrário será devolvido o site sem qualquer custo associado.
                            </p>
                        </div>
                    </div>
                </div>
                <Form defaultValue={"webUNI"} />
            </main >
            <Footer />
        </>
    );
};

export default WebsiteOnePage;