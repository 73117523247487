import React from 'react';
import { useNavigate } from 'react-router-dom';

const NavbarSecundary = () => {
    const navigate = useNavigate();

    return (
        <nav className='max-w-[1920px] mx-auto z-10 relative'>
            <div className='flex justify-center py-10'>
                <img onClick={()=> navigate("/")} className='w-24 select-none' src={`${process.env.PUBLIC_URL}/images/logoNOBG.png`} title='jtlstudios Logo' alt='jtlstudios logo' />
            </div>
        </nav>
    );
};

export default NavbarSecundary;