import React from 'react';
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

const ButtonBack = () => {

    const navigate = useNavigate();

    return (
        <div onClick={()=> navigate("/")}  className='absolute z-50 w-8 h-8 transition-all rounded-full bg-brandOrange top-10 left-10 hover:scale-125'>
        <IoMdArrowBack className='absolute text-2xl text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2' />
    </div>
    );
};

export default ButtonBack;