import React from 'react';
import NavbarSecundary from '../components/navbarSecundary';
import ButtonBack from '../components/buttonBack';

const PrivacyPolicy = () => {

    return (
        <>
            <NavbarSecundary />
            <ButtonBack />
            <main className='flex flex-col items-center justify-center px-8'>
                <h1 className="title">Política de Privacidade</h1>
                <p className='mt-8 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                    A JTL Studios está comprometida em proteger a privacidade dos utilizadores do nosso website. Esta Política de Privacidade descreve como recolhemos, usamos e protegemos as informações que você nos fornece ao utilizar o nosso website.
                </p>
                <div className='mt-12 '>
                    <h3 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>Informações que Recolhemos:</h3>
                    <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                        Ao utilizar o nosso website, podemos recolher informações pessoais que você nos fornece voluntariamente através do formulário de contacto. As informações que podemos recolher incluem:
                    </p>
                    <ul className='pl-10 mt-1 text-base font-semibold tracking-wider text-white list-disc sm:text-lg md:text-xl'>
                        <li>Nome</li>
                        <li>Email</li>
                        <li>Tipo de projeto</li>
                        <li>Descrição do projeto</li>
                    </ul>
                </div>
                <div className='mt-12 '>
                    <h3 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>Utilização de Serviços de Terceiros:</h3>
                    <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                        Utilizamos serviços de terceiros, como o Hotjar e o Google Analytics, para monitorizar e analisar o desempenho do nosso website. Esses serviços podem recolher informações sobre a sua interação com o nosso website, incluindo, mas não limitado a:
                    </p>
                    <ul className='pl-10 mt-1 text-base font-semibold tracking-wider text-white list-disc sm:text-lg md:text-xl'>
                        <li>Endereço IP</li>
                        <li>Tipo de navegador</li>
                        <li>Páginas visitadas (dentro do nosso website)</li>
                        <li>Tempo gasto em cada página</li>
                    </ul>
                </div>
                <div className='mt-12 '>
                    <h3 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>Utilização de Cookies:</h3>
                    <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                        Não utilizamos cookies no nosso website. No entanto, os serviços de terceiros que utilizamos podem utilizar cookies e tecnologias semelhantes para recolher informações sobre a sua atividade online. Recomendamos que consulte as políticas de privacidade desses serviços de terceiros para obter mais informações sobre como eles utilizam os seus dados.
                    </p>
                </div>
                <div className='mt-12 '>
                    <h3 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>Como Utilizamos as Informações:</h3>
                    <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                        Utilizamos as informações recolhidas para responder às suas consultas enviadas através do formulário de contacto e para melhorar a experiência do utilizador no nosso website. Além disso, utilizamos as informações recolhidas pelos serviços de terceiros para analisar o desempenho do nosso website e fazer melhorias.
                    </p>
                </div>
                <div className='mt-12 '>
                    <h3 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>Partilha de Informações:</h3>
                    <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                        Não partilhamos as informações pessoais dos utilizadores com terceiros, exceto quando exigido por lei ou quando necessário para fornecer os serviços solicitados por você.
                    </p>
                </div>
                <div className='mt-12 '>
                    <h3 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>Segurança das Informações:</h3>
                    <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                        Implementamos medidas de segurança para proteger as informações pessoais dos utilizadores contra acesso não autorizado ou divulgação. No entanto, não podemos garantir a segurança absoluta dos dados transmitidos pela Internet.
                    </p>
                </div>
                <div className='mt-12 '>
                    <h3 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>Contacte-nos:</h3>
                    <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                        Se tiver alguma dúvida ou preocupação sobre esta Política de Privacidade ou sobre as práticas de privacidade do nosso website, entre em contacto connosco através dos contactos fornecidos no nosso website.
                    </p>
                </div>
                <div className='my-12 '>
                    <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                        Ao utilizar o nosso website, você concorda com o processamento das suas informações conforme descrito nesta Política de Privacidade. Por favor, leia esta Política de Privacidade cuidadosamente e entre em contacto connosco se tiver alguma dúvida.
                    </p>
                    <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                        Esta Política de Privacidade foi atualizada pela última vez em 08/04/2024.
                    </p>
                </div>
            </main >
        </>
    );
};

export default PrivacyPolicy;