import React from 'react';
import NavbarSecundary from '../components/navbarSecundary';

const _404Page = () => {

    return (
        <main className='flex flex-col min-h-screen px-8 lg:px-24'>
            <NavbarSecundary />
            <div className='flex flex-col justify-center flex-grow'>
                <h1 className="title">Erro 404</h1>
                <h3 className='mt-4 text-2xl font-bold tracking-wide text-center text-midColor md:text-3xl'>Lamentamos mas não encontramos aquilo que procura.</h3>
                <img className='py-12 mx-auto select-none w-[26rem]' src={`${process.env.PUBLIC_URL}/images/404.svg`} title='404 Ilustration' alt='404 Ilustration' />
                <a href={`${process.env.PUBLIC_URL}/`} className="w-64 mx-auto button">Voltar ao Início</a>
            </div>
        </main>
    );
};

export default _404Page;