import React, { useEffect, useState } from 'react';
import NavbarSecundary from '../../components/navbarSecundary';
import Form from '../../components/form';
import ButtonBack from '../../components/buttonBack';
import { FaMinus, FaPlus } from 'react-icons/fa6';
import { Link } from 'react-scroll'
import Footer from '../../components/footer';

const WebApp = () => {

    const [pos, setPos] = useState(-800);

    useEffect(() => {
        const handleScroll = () => {
            setPos((window.scrollY * 0.5) - 800);
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    return (
        <>
            <NavbarSecundary />
            <ButtonBack />
            <main className='flex flex-col items-center px-8 lg:px-24'>
                <div className='lg:grid grid-cols-7 gap-x-32 items-center min-h-[90vh]'>
                    <div className='flex flex-col items-center col-span-4'>
                        <h1 className="title">Aplicação Web</h1>
                        <hr className='h-[1px] my-5 w-full border-none bg-gradient-to-r orangeToRedGradient' />
                        <h2 className='mb-6 text-lg font-semibold tracking-wider text-center text-white sm:text-justify sm:text-xl md:text-2xl'>
                            Website que permite interação e processamento de dados oferecendo uma experiência dinâmica ao utilizador.
                        </h2>
                        <Link className="w-64 mx-auto button" to="form" spy={true} smooth={true} offset={-20} duration={500}>
                            Saber mais
                        </Link>
                    </div>
                    <div className='my-6 lg:my-0 lg:col-span-3'>
                        <img className='mx-auto w-96 lg:w-full' src={`${process.env.PUBLIC_URL}/images/projetos/escolaDeConducaoMac.png`} alt='Exemplo de website single page' />
                    </div>
                </div>
                <div>
                    <div className='absolute z-50 title text-white select-none whitespace-nowrap font-["ethnocentric"]' style={{ left: `${pos}px` }}>
                        JTL Studios JTL Studios JTL Studios JTL Studios  JTL Studios JTL Studios JTL Studios JTL Studios  JTL Studios JTL Studios JTL Studios JTL Studios JTL Studios JTL Studios JTL Studios JTL Studios
                    </div>
                </div>
                <div className='my-24 sm:my-48'>
                    <div className='px-8 py-6'>
                        <h1 className="title">Aplicação Web</h1>
                        <p className='mt-6 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                            Com a ajuda da <span className='font-bold text-brandOrange'>JTL STUDIOS </span>
                            poderá lançar a sua marca online com um website <span className='font-bold text-brandOrange'> interativo e dinâmico </span>
                            que vai <span className='font-bold text-brandOrange'>armazenar registos em uma base de dados </span>
                            e faz operações com mesmos, apresentando o conteudo de forma dinâmica.
                            Uma aplicação web permitirá-lhe vender produtos online, realizar marcações e muito mais.
                        </p>
                        <div className='my-12'>
                            <h2 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>O que é uma Aplicação Web ?</h2>
                            <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                É um website onde o seu conteúdo é mostrado de forma dinâmica, permitindo assim que haja interatividade com o utilizador.
                                Permitindo realizar <span className='font-bold text-brandOrange'>operações complexas </span>
                                que necessitam de uma <span className='font-bold text-brandOrange'>base de dados </span>
                                como mostrar registos ao utilizador de forma dinâmica
                                receber dados introduzidos pelo utilizador e trata-los.
                                Poderá incluir um paínel de administração vários tipos de utilizadores, criação de conta no website e login, pagamentos, registo de dados entre outros.
                            </p>
                            <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                Uma aplicação web pode ser uma loja online, um blog, um site onde são permitidas a realização de marcações entre outros.
                                As possibilidades são ilimitadas, uma aplicação <span className='font-bold text-brandOrange'>permite que os utilizadores interajam com o seu site.</span>
                            </p>
                        </div>
                        <div className='mt-12'>
                            <h2 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>Será que uma aplicação é o ideal para mim ?</h2>
                            <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                Caso precise que o seu site <span className='font-bold text-brandOrange'>apresente dados </span>
                                ao utilizador <span className='font-bold text-brandOrange'>de forma dinâmica </span>
                                recorrendo a uma base de dados e que faça operações
                                com estes uma aplicação web é aquilo de que o seu negócio precisa. Caso não pretenda ter um site
                                puramente informativo sem qualquer interação com o utilizador com o utilizador então a JTL recomenda
                                um website de mutiplas páginas ou de página uníca.
                            </p>
                            <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                <span className='font-bold text-brandOrange'>Se tiver alguma duvida não hesite em contactar-nos.</span>
                            </p>
                        </div>
                    </div>

                    <div className='px-8 mt-24 sm:mt-48'>
                        <h2 className='title'> Perguntas Frequentes</h2>
                        <div className='mt-12'>
                            <div className='my-12'>
                                <h3 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>Como funciona um formulário de contacto ?</h3>
                                <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                    Se desejar adicionar um formulário de contacto ao seu website, este irá será similar ao formulário de contacto presente no nosso website.
                                </p>
                                <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                    Após ser preenchido <span className='font-bold text-brandOrange'>será enviado um email </span>
                                    para uma conta de email à sua escolha
                                    <span className='font-bold text-brandOrange'> com as informações preenchidas </span> no formuário.
                                    Caso pretanda guardar informações em uma base de dados haverá um custo adicional, para mais informações contacte-nos.
                                </p>
                            </div>
                            <div className='my-12'>
                                <h3 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>O que siginifica registo de domínio ?</h3>
                                <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                    Um domínio é o <span className='font-bold text-brandOrange'>endereço do seu website na internet</span>, quando pesquisa: 'www.jtlstudios.pt' o 'jtlstudios.pt' é o domínio do nosso website.
                                </p>
                                <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                    Pode optar por um dominio predefinido por exemplo: 'www.o-nome-do-site.netlify.app'
                                    apesar de não haver custos associados
                                    é uma abordagem menos profissional e irá afetar negativamente o seu posicionamento nas pesquisas do google.
                                </p>
                                <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                    Caso pretenda que seja efetuado o registo de domínio, 'www.nome-do-site.pt'
                                    iremos registar o seu domínio o que irá ter um custo associado o qual irá pagar anualmente à entidade provedora domínio.
                                    Um domínio registado demostra profissionalismo e credibilidade junto dos seus clientes.
                                </p>
                                <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                    Na JTL trabalhamos com ambos os tipos de domínio e
                                    poderá escolher <span className='font-bold text-brandOrange'>qualquer um dos dois</span> sem ter nenhum custo associado para comnosco.
                                </p>
                                <div className='grid grid-cols-1 mt-6 sm:grid-cols-2 gap-y-5'>
                                    <div className='w-[60%] mx-auto '>
                                        <h4 className='text-xl font-bold tracking-wide text-white'>
                                            Domínio predefinido
                                            <span className='text-sm text-gray-100'> (nome-do-site.netlify.app)</span>
                                        </h4>
                                        <div className='flex items-center mt-3 text-green-600 gap-x-3'>
                                            <FaPlus />
                                            <p>Gratuito</p>
                                        </div>
                                        <div className='flex items-center text-red-600 gap-x-3'>
                                            <FaMinus />
                                            <p>Limitações na costumização</p>
                                        </div>
                                        <div className='flex items-center text-red-600 gap-x-3'>
                                            <FaMinus />
                                            <p>Imagem menos profissional</p>
                                        </div>
                                        <div className='flex items-center text-red-600 gap-x-3'>
                                            <FaMinus />
                                            <p>Afeta o desempenho do site nas pesquisas no google</p>
                                        </div>
                                    </div>
                                    <div className='w-[60%] mx-auto'>
                                        <h4 className='text-xl font-bold tracking-wide text-white'>
                                            Domínio registado
                                            <span className='text-sm text-gray-100'> (nome-do-site.pt)</span>
                                        </h4>
                                        <div className='flex items-center mt-3 text-green-600 gap-x-3'>
                                            <FaPlus />
                                            <p>Totalmente personalizável</p>
                                        </div>
                                        <div className='flex items-center text-green-600 gap-x-3'>
                                            <FaPlus />
                                            <p>Demostra Credibilidade e Profissionalismo</p>
                                        </div>
                                        <div className='flex items-center text-green-600 gap-x-3'>
                                            <FaPlus />
                                            <p>Melhora o desempenho do site nas pesquisas no google</p>
                                        </div>
                                        <div className='flex items-center text-red-600 gap-x-3'>
                                            <FaMinus />
                                            <p>Custo anual</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h3 className='text-2xl font-bold tracking-wide text-midColor md:text-3xl'>Como funciona o processo de aquisição de um webiste ?</h3>
                            <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                A <span className='font-bold text-brandOrange'>JTL STUDIOS pretende a total satisfação dos seus clientes</span>,
                                deste modo antes de começarmos o desenvolvimento avaliaremos as suas
                                necessiades e garantimos que o nosso serviço vá de encontro as mesmas,
                                preservando a identidade da sua marca.
                                Ao longo do do processo de desenvolvimento teremos tantas reuniões de avaliação de progresso quanto desejar
                                garantindo que o website esteja <span className='font-bold text-brandOrange'>100% à sua medida.</span>
                            </p>
                            <p className='mt-2 text-lg font-semibold tracking-wider text-white sm:text-xl md:text-2xl'>
                                Depois do desenvolvimento é feita uma reunião final para garantir que todos os objetivos foram cumpridos.
                                website será colocado num domínio à sua escolha e entregue o pagamento só é efetuado após a entrega caso
                                esteja <span className='font-bold text-brandOrange'>100% satisfeito</span>, caso contrário será devolvido o site sem qualquer custo associado.
                            </p>
                        </div>
                    </div>
                </div>
                <Form defaultValue={"WebApp"} />
            </main >
            <Footer />
        </>
    );
};

export default WebApp;